import mail from '../resources/mail.png'
import cphone from '../resources/phone.png'
import locationimg from '../resources/location.png'
const Contactus=
[
    {
        cerimg: mail,
        textwri: "Email Address",
        undert: 'info@thebrandbucks.com'
    },

    // {
    //     cerimg: locationimg,
    //     textwri: "Location",
    //     undert: "The Brand Bucks",
    //     po:   "907, DAMAC Business Tower, Business Bay, PO 1207, Dubai, UAE"

    // },
    {
        cerimg: cphone,
        textwri: "Phone Number",
        undert: "+971 55 253 4230"

    },

]
export default Contactus